import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
import App from "./App.vue";
import ConfigError from "./ConfigError.vue";
import i18n from "./plugins/i18n";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import SignalR from "./utils/signalr";
import Measure from "./models/Measure";
import ApiRequests from "./utils/requests";
import moment from "moment";
import UserInfo from "./models/UserInfo";
import CustomerInfo from "./models/CustomerInfo";
import AssetInfo from "./models/AssetInfo";
import GroupInfo from "./models/GroupInfo";
import Messages from "./utils/messages";
import AssetEventsRequestBody from "./models/AssetEventsRequestBody";
import Event from "./models/Event";
import { Map, TileLayer, OsmSource, XyzSource, Overlay } from "vuelayers";
import { Chart, registerables } from "chart.js";
import { MatrixController, MatrixElement } from "chartjs-chart-matrix";
import { CrosshairPlugin } from "chartjs-plugin-crosshair";
import zoomPlugin from "chartjs-plugin-zoom";
import annotationPlugin from "chartjs-plugin-annotation";
import "chartjs-adapter-moment";

ApiRequests.getConfig(
  Object.keys(store.getters.config),
  async (res) => {
    const config = res.data;
    const locales = {
      all: "VUE_APP_LOCALES",
      fallback: "VUE_APP_I18N_FALLBACK_LOCALE",
      selected: "VUE_APP_I18N_LOCALE",
    };

    Object.keys(config).forEach((key) => {
      store.commit("addConfig", { key, value: config[key] });
    });

    const availableLocales = store.getters.array(locales.all);

    if (!availableLocales.includes(store.getters.string(locales.fallback))) {
      availableLocales.push(store.getters.string(locales.fallback));
      store.commit("addConfig", {
        key: locales.all,
        value: availableLocales.join(","),
      });
    }

    moment.locale(store.getters.string(locales.selected));

    // Start the app

    Vue.config.productionTip = false;

    // Create app events bus
    Vue.prototype.$bus = new Vue();

    Vue.use(SignalR);
    Vue.use(Map);
    Vue.use(TileLayer);
    Vue.use(OsmSource);
    Vue.use(XyzSource);
    Vue.use(Overlay);
    Vue.use(VueCompositionAPI);

    Chart.register(...registerables);
    Chart.register(MatrixController, MatrixElement);
    Chart.register(CrosshairPlugin, zoomPlugin);
    Chart.register(annotationPlugin);

    Vue.mixin({
      data: () => ({
        pwdRegExp: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        localePath: "VUE_APP_I18N_LOCALE",
        modifiedLanguage: undefined,
        modifiedDetailsGaps: undefined,
        modifiedAnalysisGaps: undefined,
        fetching: false,
      }),

      computed: {
        clientId() {
          return this.$store.getters.clientId;
        },
        currentUser() {
          return this.$store.getters.currentUser ?? new UserInfo();
        },
        currentCompany() {
          return this.$store.getters.currentCompany;
        },
        emailActive() {
          return this.$store.getters.emailActive;
        },
        emailSuggestions() {
          return this.$store.getters.emailSuggestions;
        },
        smsActive() {
          return this.$store.getters.smsActive;
        },
        smsSuggestions() {
          return this.$store.getters.smsSuggestions;
        },
        telegramActive() {
          return this.$store.getters.telegramActive;
        },
        telegramSuggestions() {
          return this.$store.getters.telegramSuggestions;
        },
        pluginsStatus() {
          return this.$store.getters.pluginsStatus;
        },
        browserName() {
          return (
            this.$store.getters.string("VUE_APP_APP_BROWSER_NAME") ||
            this.$t("appDefaultName")
          );
        },
        appName() {
          return (
            this.$store.getters.string("VUE_APP_APP_NAME") ||
            this.$t("appDefaultName")
          );
        },
        appLogo() {
          return this.$store.getters.string("VUE_APP_APP_LOGO");
        },
        appColor() {
          return this.$store.getters.string("VUE_APP_APP_COLOR") || "#0c1d69";
        },
        vendorName() {
          const vendor = this.$store.getters.string("VUE_APP_APP_VENDOR");

          return vendor !== "VUE_APP_APP_VENDOR" ? vendor : undefined;
        },
        vendorLogo() {
          const logo = this.$store.getters.string("VUE_APP_APP_VENDOR_ICON");

          return logo !== "VUE_APP_APP_VENDOR_ICON" ? logo : undefined;
        },
        userIcon() {
          switch (this.currentUser.role) {
            case UserInfo.roles.SUPER_ADMIN:
              return "mdi-shield-account-outline";
            case UserInfo.roles.ADMIN:
            case UserInfo.roles.MANAGER:
              return "mdi-account-supervisor-circle-outline";
            default:
              return "mdi-account-circle-outline";
          }
        },
        loggedIn() {
          return this.$store.getters.loggedIn;
        },
        assetsList() {
          return this.$store.getters.allAssets.filter(
            (asset) => !!asset.serial
          );
        },
        unfilteredAssetsList() {
          return this.$store.getters.allAssets;
        },
        groupsList() {
          return this.$store.getters.allGroups;
        },
        customersList() {
          return this.$store.getters.allCustomers;
        },
        usersList() {
          return this.$store.getters.allUsers;
        },
        outlinedPref() {
          return this.$store.getters.bool("VUE_APP_OUTLINED_COMPONENTS");
        },
        currentLang() {
          return this.$store.getters.string(this.localePath);
        },
        lastSeenTopic() {
          const topic = this.$store.getters
            .string("VUE_APP_LAST_SEEN_TOPIC")
            .split("/");

          topic.splice(1, 0, "@[^@$/]+");

          return "^" + topic.join("/") + "$";
        },
        lastSeenBySerial() {
          return (serial) =>
            this.$store.getters.lastSeenBySerial(serial) || undefined;
        },
        isOffline() {
          return this.$store.getters.offline;
        },
        signalRBaseRegexes() {
          return [...Messages.SIGNALR_BASE_REGEXES, this.lastSeenTopic];
        },
        alarmsCountById() {
          return this.$store.getters.alarmsCountById;
        },
        eventMessages() {
          return this.$store.getters.eventMessages;
        },
        availableMeasures() {
          return this.$store.getters.measures;
        },
        currentPage: {
          get() {
            return this.$store.getters.currentPage;
          },
          set() {
            /* Do nothing */
          },
        },
        currentCustomPage: {
          get() {
            return this.$store.getters.currentCustomPage;
          },
          set() {
            /* Do nothing */
          },
        },
        selectedLanguage: {
          get() {
            return this.currentLang || "en";
          },
          set(value) {
            this.modifiedLanguage = value;
          },
        },
        selectedDetailsInterpolate: {
          get() {
            return (
              this.$store.getters.bool("VUE_APP_DETAILS_INTERPOLATE") ?? true
            );
          },
          set(value) {
            this.modifiedDetailsGaps = value;
          },
        },
        selectedAnalysisInterpolate: {
          get() {
            return (
              this.$store.getters.bool("VUE_APP_ANALYSIS_INTERPOLATE") ?? true
            );
          },
          set(value) {
            this.modifiedAnalysisGaps = value;
          },
        },
      },

      watch: {
        modifiedLanguage(newValue) {
          this.saveLanguageToStore(newValue);
        },
        modifiedDetailsGaps(newValue) {
          this.saveGapsToStore(newValue, "VUE_APP_DETAILS_INTERPOLATE");
        },
        modifiedAnalysisGaps(newValue) {
          this.saveGapsToStore(newValue, "VUE_APP_ANALYSIS_INTERPOLATE");
        },
      },

      methods: {
        saveLanguageToStore(lang) {
          this.$store.commit("addConfig", {
            key: this.localePath,
            value: lang,
          });
          localStorage[this.localePath] = lang;
          this.$vuetify.lang.current = lang;
          this.$i18n.locale = lang;
          moment.locale(lang);
        },

        saveGapsToStore(gaps, index) {
          this.$store.commit("addConfig", {
            key: index,
            value: gaps,
          });
          localStorage[index] = gaps;
        },

        isOfflineBySerial(serial) {
          const now = moment();
          const ts = this.lastSeenBySerial(serial);
          const timeout = this.$store.getters.number(
            "VUE_APP_OFFLINE_TIMEOUT_MINUTES"
          );

          if (ts === undefined) {
            return true;
          }

          // Create a copy to avoid side effects
          const tsCopy = moment(ts.format());

          tsCopy.add(timeout, "minutes");

          return tsCopy.isBefore(now, "minutes");
        },

        getMeasureValue(measure) {
          if (measure === undefined) return new Measure();

          const response =
            measure.value != undefined ? measure.value : new Measure();

          Object.keys(measure).forEach((k) =>
            k !== "value" ? (response.metadata[k] = measure[k]) : undefined
          );

          return response;
        },

        getReadableTimestamp(timestamp, addHoursToDate = false) {
          if (timestamp == undefined) {
            return "--";
          }

          const ts = moment.utc(timestamp).local();
          const today = moment();

          return ts.format(
            ts.isBefore(today, "day")
              ? `${this.$t("tzLocale")}${addHoursToDate ? " HH:mm" : ""}`
              : "HH:mm"
          );
        },

        getFullTimestamp(timestamp) {
          if (timestamp == undefined) {
            return "--";
          }

          const ts = moment.utc(timestamp).local();
          const today = moment();

          return ts.format(
            ts.isBefore(today, "day")
              ? `${this.$t("tzLocale")} HH:mm:ss`
              : "HH:mm:ss"
          );
        },

        readableLanguage(lang) {
          return this.$t(
            `language${lang.charAt(0).toUpperCase()}${lang.slice(1)}`
          );
        },

        async fetchAllData(callback = undefined) {
          this.fetching = true;

          let assetsCount = {};
          let assets = [];
          let groups = [];
          let customers = [];
          let users = [];
          let eventsMessagesMap = {};

          // Fetch available topics
          ApiRequests.getAssetsTopics(
            undefined,
            (res) => {
              this.$store.commit("setAvailableTopics", res.data);
            },
            (err) =>
              process.env.NODE_ENV === "development"
                ? console.error(err)
                : undefined
          );

          try {
            assetsCount = (
              await ApiRequests.getAssetsCount(
                this.currentUser.companyId || undefined,
                undefined,
                undefined
              )
            ).data;
          } catch {
            /* Something went wrong */
          }

          try {
            // eslint-disable-next-line prettier/prettier
            assets = (await ApiRequests.getAssets(undefined, undefined)).data;
          } catch {
            /* Something went wrong */
          }

          try {
            groups = (await ApiRequests.getGroups(undefined, undefined)).data;
          } catch {
            /* Something went wrong */
          }

          try {
            // Fetch events messages
            eventsMessagesMap = (
              await ApiRequests.getEventsMessages(
                undefined,
                undefined,
                undefined
              )
            ).data;
          } catch {
            /* Something went wrong */
          }

          if (this.currentUser.isSuperAdmin) {
            try {
              // eslint-disable-next-line prettier/prettier
              customers = (await ApiRequests.getCustomers(undefined, undefined)).data;
            } catch {
              /* Something went wrong */
            }
          }

          if (!this.currentUser.isOperator) {
            try {
              users = (await ApiRequests.getUsers(undefined, undefined)).data;
            } catch {
              /* Something went wrong */
            }
          }

          // Map data to models
          groups = groups.map((f) => {
            const fetchedGroup = new GroupInfo(f);
            const groupCount =
              assetsCount[fetchedGroup.companyId || "-"]?.[fetchedGroup.id] ??
              0;

            fetchedGroup.assets = groupCount;

            return fetchedGroup;
          });
          customers = customers.map((c) => {
            const fetchedCustomer = new CustomerInfo(c);
            const customerCount = assetsCount[fetchedCustomer.id];
            let total = 0;

            if (customerCount != undefined) {
              Object.keys(customerCount).forEach(
                (k) => (total += customerCount[k])
              );
            }

            fetchedCustomer.assets = total;

            return fetchedCustomer;
          });
          users = users.map((u) => {
            const fetchedUser = new UserInfo(u);

            fetchedUser.isSelectable =
              fetchedUser.id !== this.currentUser.id &&
              fetchedUser.role >= this.currentUser.role &&
              fetchedUser.createdBy != null;
            fetchedUser.bulkDisabled = !fetchedUser.isSelectable;

            if (this.currentUser.isSuperAdmin) {
              fetchedUser.companyName = customers.find(
                (c) => c.id === fetchedUser.companyId
              )?.name;
            }

            fetchedUser.groupNames = fetchedUser.groupIds.map(
              (id) => groups.find((f) => f.id === id)?.name
            );

            return fetchedUser;
          });
          assets = assets.map((v) => {
            const fetchedAsset = new AssetInfo(v);

            if (this.currentUser.isSuperAdmin) {
              fetchedAsset.companyName = customers.find(
                (c) => c.id === fetchedAsset.companyId
              )?.name;
            }

            fetchedAsset.groupName = groups.find(
              (f) => f.id === fetchedAsset.groupId
            )?.name;

            return fetchedAsset;
          });

          // Commit data to store
          this.$store.commit("setGroups", groups);
          this.$store.commit("setUsers", users);
          this.$store.commit("setCustomers", customers);
          this.$store.commit("enumerateAssets", assets);
          this.$store.commit("setEventMessages", eventsMessagesMap);

          // Fetch plugins info
          await this.getPlugins();

          // Fetch last 12h alarms
          this.fetchAlarmsCount();

          // Fetch last seen data
          ApiRequests.assetLastContact(
            this.$store.getters.string("VUE_APP_LAST_SEEN_TOPIC"),
            undefined,
            (res) => this.$store.dispatch("setLastContacts", res.data),
            (err) =>
              process.env.NODE_ENV === "development"
                ? console.error(err)
                : undefined
          );

          // Fetch commands
          this.fetchCommands();

          if (callback != undefined) {
            callback();
          }

          this.fetching = false;
        },

        async getPlugins() {
          try {
            const emailPluginInfo = (
              await ApiRequests.getPluginsStates(
                "mailer-plugin",
                "MAILING_LISTS",
                undefined,
                undefined
              )
            ).data;
            const usersEmails = (
              await ApiRequests.getUsersEmails(undefined, undefined)
            ).data;

            this.$store.commit(
              "setEmailStatus",
              emailPluginInfo["mailer-plugin"].enabled
            );
            this.$store.commit("setEmailSuggestions", [
              ...Object.keys(
                JSON.parse(emailPluginInfo["mailer-plugin"].setting || "{}")
              ),
              ...(usersEmails || []),
            ]);
          } catch {
            /* Unhandled exception */
          }
        },

        fetchCommands() {
          ApiRequests.getCommands(
            (res) => {
              this.$store.commit("setAssetsCommands", res.data);
            },
            (err) =>
              process.env.NODE_ENV === "development"
                ? console.error(err)
                : undefined
          );
        },

        fetchAlarmsCount() {
          const statsRequest = new AssetEventsRequestBody();

          statsRequest.activeOnly = true;
          statsRequest.starting = moment().subtract(12, "hours").format();
          statsRequest.severities = [
            Event.severity.HIGH,
            Event.severity.MEDIUM,
            Event.severity.LOW,
          ];

          ApiRequests.getSavedEvents(
            statsRequest,
            (res) => {
              const alarms = res.data
                .map((e) => {
                  const normalized = {};

                  Object.keys(e).forEach((k) => {
                    // eslint-disable-next-line prettier/prettier
                    normalized[k.charAt(0).toUpperCase() + k.substring(1)] = e[k];
                  });

                  return new Event(normalized);
                })
                .reduce((count, alarm) => {
                  // eslint-disable-next-line prettier/prettier
                  const assetInfo = this.$store.getters.assetIdsByMac(alarm.originId);
                  const assetId = assetInfo.id;
                  const groupId = assetInfo.group;

                  const setCount = (id, severity) => {
                    if (count[id] == undefined) {
                      count[id] = {
                        [Event.severity.HIGH]: 0,
                        [Event.severity.MEDIUM]: 0,
                        [Event.severity.LOW]: 0,
                      };
                    }

                    count[id][severity] += 1;
                  };

                  setCount(assetId, alarm.severity);

                  if (groupId != undefined && groupId != "") {
                    setCount(groupId, alarm.severity);
                  }

                  return count;
                }, {});

              this.$store.commit("setAlarmsCountById", alarms);
            },
            (err) => {
              if (process.env.NODE_ENV === "development") console.error(err);
            }
          );
        },

        logout(justRedirect = false) {
          const currentRoute = this.$router.currentRoute.path;

          if (justRedirect) {
            if (currentRoute !== "/login")
              this.$router.replace({ path: "/login" });
          } else {
            ApiRequests.userLogout(
              () =>
                currentRoute !== "/login"
                  ? this.$router.replace({ path: "/login" })
                  : undefined,
              (err) => {
                if (process.env.NODE_ENV === "development") console.error(err);
                this.$bus.$emit(Messages.ERROR_MSG, {
                  error: err?.response?.data?.error || err,
                  description: this.$t("logoutFailure"),
                });
              }
            );
          }
        },
      },
    });

    new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: (h) => h(App),
    }).$mount("#app");
  },
  (err) => {
    if (process.env.NODE_ENV === "development") console.error(err);
    new Vue({
      router,
      vuetify,
      render: (h) => h(ConfigError),
    }).$mount("#app");
  }
);
